<template>
  <div class="rounded-lg border bg-white px-7 pb-12 pt-6">
    <div>
      <img
        v-show="hasError === 0"
        class="mx-auto h-28"
        src="@/assets/svgs/success.svg"
      />
      <template v-if="hasError === 1">
        <img
          v-if="showError === 'abandoned'"
          class="mx-auto h-28"
          src="@/assets/svgs/canceled-payment.gif"
        />
        <img
          v-else
          class="mx-auto h-28"
          src="@/assets/svgs/animate-payment-failed.gif"
        />
      </template>
    </div>
    <div class="mt-4 text-center">
      <DialogTitle as="h2" class="font-bold text-primary">{{
        hasError === 0 ? 'Successful' : `Payment ${showError}`
      }}</DialogTitle>
      <DialogDescription
        class="mt-4 text-center text-sm font-normal text-[#1A2D5B]"
        >{{
          errorMessage ? errorMessage : ' Your have succesfully made a payment'
        }}
      </DialogDescription>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { DialogTitle, DialogDescription } from '@/components/ui/dialog'

const route = useRoute()
const hasError = ref()
const errorMessage = ref('')
const showError = ref('')

const { $event } = useNuxtApp()

onMounted(() => {
  if (typeof route.query.error === 'string') {
    const errMessage = route.query.error
    const fromString = 'tl_hpp_'
    const after = errMessage.replaceAll(fromString, '')
    showError.value = after
    if (showError.value === 'abandoned') {
      errorMessage.value =
        'Your payment has been abandoned. No funds will be deducted from your account, and the transaction will not be completed.'
    } else {
      errorMessage.value =
        'Your bank couldn’t process your payment. Please try again or change your payment method.'
    }
    hasError.value = 1
  } else {
    $event('track:mixpanel', {
      event: 'Pay Supplier',
      data: {
        'Payment method': 'open-bank',
      },
    })
    hasError.value = 0
  }
})
</script>
