<script setup lang="ts">
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import MyWorker from '@/assets/workers/worker?worker'

interface Emits {
  (e: 'stayOn'): void
  (e: 'logout'): void
}

const worker = new MyWorker()

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(duration)

const emit = defineEmits<Emits>()
const seconds = ref(30)
const isLoggingOut = ref(false)

const formattedTime = computed(() => {
  return dayjs.duration(seconds.value * 1000).format('m:ss')
})

function cancel() {
  emit('stayOn')
}

onMounted(() => {
  worker.postMessage({ name: 'startTimer', intervalTime: 1000 })

  worker.onmessage = (event) => {
    if (event.data.name === 'tick') {
      if (seconds.value <= 0) {
        worker.postMessage('stopTimer')
        seconds.value = 0
        isLoggingOut.value = true
        emit('logout')
      } else {
        seconds.value--
      }
    }
  }
})

onBeforeUnmount(() => {
  worker.postMessage({ name: 'stopTimer' })
  worker.terminate()
})
</script>

<template>
  <div class="text-primary">
    <div v-if="seconds >= 0">
      <p
        class="mx-auto flex aspect-square h-[4.5rem] items-center justify-center rounded-full bg-[#F3F7FF] text-2xl font-bold"
      >
        {{ formattedTime }}
      </p>
    </div>

    <div class="mt-4 space-y-2 text-center">
      <p class="text-xl font-bold">Are you still there?</p>
      <p>
        It looks like you've been inactive for a while. For security reasons,
        we'll automatically log you out soon.
      </p>
    </div>

    <div class="mt-6 grid grid-cols-2 gap-2">
      <Button
        variant="outline"
        :disabled="isLoggingOut"
        @click="
          () => {
            isLoggingOut = true
            emit('logout')
          }
        "
        >{{ isLoggingOut ? 'Logging out ...' : 'Log me out' }}</Button
      >
      <Button variant="outline" :disabled="isLoggingOut" @click="cancel">
        {{ isLoggingOut ? 'Logging out ...' : 'Keep me signed in' }}</Button
      >
    </div>
  </div>
</template>
