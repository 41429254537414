<template>
  <div
    class="group relative z-10 max-h-[400px] min-h-[200px] w-full overflow-x-hidden rounded-lg border-2 bg-white sm:max-h-[700px] sm:w-[600px] md:min-h-[300px]"
  >
    <div class="absolute -z-10 flex h-full w-full items-center justify-center">
      <full-page-loader />
    </div>
    <div v-if="modalState.selectedInvoice" class="z-20 h-full w-full">
      <div
        v-if="
          modalState.selectedInvoice.type === 'image/png' ||
          modalState.selectedInvoice.type === 'image/jpeg'
        "
        class="h-full w-full"
      >
        <img :src="fileUrl || ''" class="h-full w-full object-cover" />
      </div>
      <div
        v-else-if="modalState.selectedInvoice.type === 'application/pdf'"
        class="relative"
      >
        <div
          class="absolute top-0 z-30 flex w-full items-center justify-center text-center duration-200 group-hover:-translate-y-full"
        >
          <div
            class="flex items-center rounded-b-md border-x-2 border-b-2 bg-white px-3 py-1 text-sm"
          >
            <div>Scroll down</div>
            <div>
              <svg
                width="13"
                height="13"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="ml-0.5 mt-0.5"
              >
                <path
                  d="M3.85355 2.14645C3.65829 1.95118 3.34171 1.95118 3.14645 2.14645C2.95118 2.34171 2.95118 2.65829 3.14645 2.85355L7.14645 6.85355C7.34171 7.04882 7.65829 7.04882 7.85355 6.85355L11.8536 2.85355C12.0488 2.65829 12.0488 2.34171 11.8536 2.14645C11.6583 1.95118 11.3417 1.95118 11.1464 2.14645L7.5 5.79289L3.85355 2.14645ZM3.85355 8.14645C3.65829 7.95118 3.34171 7.95118 3.14645 8.14645C2.95118 8.34171 2.95118 8.65829 3.14645 8.85355L7.14645 12.8536C7.34171 13.0488 7.65829 13.0488 7.85355 12.8536L11.8536 8.85355C12.0488 8.65829 12.0488 8.34171 11.8536 8.14645C11.6583 7.95118 11.3417 7.95118 11.1464 8.14645L7.5 11.7929L3.85355 8.14645Z"
                  fill="currentColor"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <vue-pdf-embed :source="fileUrl || ''" />
      </div>
      <div v-else class="z-20 bg-white py-20 text-center sm:py-40">
        File cannot be displayed
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import VuePdfEmbed from 'vue-pdf-embed'
import 'vue-pdf-embed/dist/style/index.css'
import FullPageLoader from '@/components/loaders/full-page-loader.vue'

import { modalState } from '@/services/modal'

const fileUrl = computed(() => {
  if (modalState.value.selectedInvoice) {
    return URL.createObjectURL(modalState.value.selectedInvoice)
  }
})
</script>
