<script setup lang="ts">
// interface Props {}
// const props = defineProps<Props>()
import { PhCheck } from '@phosphor-icons/vue'
import { toast } from '~/components/ui/toast'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
interface Emits {
  (e: 'add-new'): void
}

const emit = defineEmits<Emits>()
const profileStore = useProfileStore()
const { allMyOrganisations, currentlySelectedOrganisation } =
  storeToRefs(profileStore)
const {
  utils: { setOrganisationId },
} = useUserFlow()

function switchOrganisation(organisationId: string) {
  if (organisationId === currentlySelectedOrganisation.value?.id) {
    return toast({ title: 'Organisation already selected' })
  }
  setOrganisationId(organisationId)

  toast({
    title: 'Switching organisation',
    description: 'Please wait, while we switch organisation',
  })

  setTimeout(() => {
    window.location.href = '/dashboard'
  }, 700)
}
</script>

<template>
  <div class="bg-white py-4">
    <p class="px-4 pb-2 text-xs font-medium uppercase text-[#94A3B8]">
      My Organisations
    </p>
    <div class="custom-scrollbar mt-2 max-h-80 space-y-2 overflow-auto">
      <div
        v-for="organisation in allMyOrganisations"
        :key="organisation.id"
        role="button"
        class="w-full cursor-pointer border-b px-4 pb-2 pt-1 font-bold uppercase leading-7 text-primary last-of-type:border-b-0 hover:bg-muted"
        @click="switchOrganisation(organisation.id)"
      >
        <TooltipProvider :delay-duration="0">
          <Tooltip>
            <TooltipTrigger
              as="div"
              class="flex w-full items-center gap-2 truncate"
            >
              <p class="flex-grow truncate">
                {{ organisation.trading_name }}
              </p>

              <span
                v-if="currentlySelectedOrganisation?.id === organisation.id"
              >
                <ph-check :size="20" color="#29CC6A" />
              </span>
            </TooltipTrigger>
            <TooltipContent
              as="span"
              class="z-[200000000000] text-pretty text-xs font-medium text-primary"
              align="start"
            >
              {{ organisation.trading_name }}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
    <div class="mt-4 px-4">
      <!-- TODO: Implement button action -->
      <Button class="w-full" @click="emit('add-new')"
        >Add new organisation</Button
      >
    </div>
  </div>
</template>
