<template>
  <div class="rounded-lg border bg-white px-7 pb-12 pt-6">
    <div>
      <img class="mx-auto h-28" src="@/assets/svgs/success.svg" />
    </div>
    <div class="mt-4 text-center">
      <DialogTitle as="h2" class="font-bold text-primary"
        >Connection successful</DialogTitle
      >
      <DialogDescription class="mt-3"
        >You have successfully connected
        {{ selectedPlatform?.name ? `to ${selectedPlatform.name}` : '' }},
      </DialogDescription>
      <Button class="mt-8" @click="closeModal">Close window</Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useConnectionsStore } from '@/stores/connections'
import { DialogTitle, DialogDescription } from '@/components/ui/dialog'

const { selectedPlatform } = useConnectionsStore()

const emit = defineEmits(['closeModal'])
const closeModal = () => {
  emit('closeModal')
  setTimeout(() => window.location.reload(), 500)
}
</script>
