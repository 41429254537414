<template>
  <div class="mx-auto max-w-[432px] rounded-lg border bg-white p-8">
    <DialogTitle
      v-if="showTitle"
      as="h2"
      class="pb-7 text-center text-xl font-bold text-primary"
    >
      Add new bank account
    </DialogTitle>
    <div class="grid grid-cols-1 gap-x-5 gap-y-5">
      <div v-click-outside="handleClickOutside">
        <Label for="first_name" class="mb-1 text-sm font-medium text-[#132248]"
          >Bank name</Label
        >
        <div class="group/drop relative w-full">
          <div class="relative">
            <Input
              v-model.trim="searchTerm"
              type="text"
              placeholder="Search by bank name"
              class=""
            />
          </div>
          <span class="text-xs font-medium text-[#B54708]">{{
            bankCodeError
          }}</span>
          <div
            v-if="isLoading || isLoadingProviders"
            class="absolute right-3 top-1/2 -translate-y-1/2 rounded-full"
          >
            <the-icon
              icon-name="spinner"
              size="s"
              class="animate-spin text-gray-400"
            />
          </div>
          <template v-if="isDropdownVisible">
            <div
              v-if="filteredProviders.length !== 0"
              class="absolute top-12 z-10 w-full rounded-md border border-gray-200 bg-white py-1 shadow-sm"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex="{-1}"
            >
              <ScrollArea
                :class="filteredProviders.length > 5 ? 'h-[200px]' : 'h-auto'"
              >
                <button
                  v-for="bank in filteredProviders"
                  :key="bank.provider_id"
                  type="button"
                  class="flex w-full flex-col border-b px-3 py-2 text-sm text-primary last:border-0 hover:bg-gray-100 hover:text-black"
                  role="menuitem"
                  @click="handleClick(bank)"
                >
                  <div>
                    <span class="py-[6px]">
                      <img
                        :src="bank.logo_url"
                        :alt="bank.display_name"
                        class="mr-2 inline-block h-8 w-8"
                      />
                      <span class="text-sm font-medium text-primary">{{
                        bank.display_name
                      }}</span>
                    </span>
                  </div>
                </button>
              </ScrollArea>
            </div>
          </template>
        </div>
        <div
          v-if="
            (searchTerm.length > 1 &&
              !filteredProviders.length &&
              !isLoading &&
              !isLoadingProviders) ||
            bankNotSupported
          "
          class="mt-3 bg-[#FEF0C7] text-center"
        >
          <span class="text-xs font-medium text-[#B54708]"
            >This bank is not supported, please try another bank or use another
            payment method available to you.</span
          >
        </div>
      </div>

      <div>
        <Label for="last_name" class="mb-1 text-sm font-medium text-[#132248]"
          >Sort code</Label
        >
        <div>
          <Input
            id="sort_code"
            v-model="sortcode"
            v-cleave="{
              delimiter: '-',
              blocks: [2, 2, 2],
              onlyPositive: true,
            }"
            maxlength="8"
            type="text"
            placeholder="Enter your sort code"
          />
        </div>
        <span class="text-xs font-medium text-[#B54708]">{{
          sortCodeError
        }}</span>
      </div>
      <div>
        <Label for="email" class="mb-1 text-sm font-medium text-primary"
          >Account number
        </Label>
        <Input
          id="account_number"
          v-model="accountNumber"
          maxlength="8"
          type="text"
          placeholder="Enter your account number"
        />
        <span class="text-xs font-medium text-[#B54708]">{{
          accountNumberError
        }}</span>
      </div>

      <div>
        <Label for="email" class="mb-1 text-sm font-medium text-primary"
          >Account nickname (optional)
        </Label>
        <Input
          id="account_number"
          v-model="bankAccountNickName"
          type="text"
          placeholder="Enter account nickname"
        />
      </div>
    </div>

    <Button
      :disabled="isLoading"
      variant="default"
      class="mt-8 w-full"
      @click.prevent="addBank"
      >{{ isLoading ? 'Processing' : 'Add bank account' }}</Button
    >
  </div>
</template>

<script setup lang="ts">
import _ from 'lodash'
import { useQuery } from '@tanstack/vue-query'
import { useProfileStore } from '@/stores/profile'
import TheIcon from '@/components/shared/the-icon.vue'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useToast } from '~/components/ui/toast/use-toast'
import { setModalState } from '@/services/modal'
import { Guid } from '~/utils/guide'
import { PAYMENT_QUERY_KEYS } from '~/constants/queryKeys/paymentsQueryKeys'
import type { ISupportedPaymentProvider } from '~/types/models/payment.model'
import type { IBank } from '~/shared/interfaces'
interface Props {
  showTitle?: boolean
}

withDefaults(defineProps<Props>(), {
  showTitle: true,
})

const bankStore = useBankStore()
const { bankAccountToWorkOn } = storeToRefs(bankStore)
const sortcode = ref<string>(bankAccountToWorkOn.value?.sort_code || '')
const accountNumber = ref<string>('')
const bankAccountNickName = ref('')

const searchTerm = ref('')
const isLoading = ref(false)
const bankData = ref<ISupportedPaymentProvider>()
const isDropdownVisible = ref(false)
const { toast } = useToast()
const { $lenkieBankingApi, $event, $api } = useNuxtApp()
const sortCodeError = ref('')
const accountNumberError = ref('')
const bankCodeError = ref('')
const profileStore = useProfileStore()
const bankNotSupported = ref(false)
const accountingPlatformId = ref()

const handleClickOutside = () => {
  isDropdownVisible.value = false
}

const { data: allSupportedProviders, isLoading: isLoadingProviders } = useQuery(
  {
    queryKey: PAYMENT_QUERY_KEYS.GET_SUPPORTED_PAYMENT_PROVIDERS,
    queryFn: $api.banking.payments.getSupportedPaymentProviders,
    select(res) {
      return res.data.result
    },
  },
)

const filteredProviders = computed(() => {
  if (!allSupportedProviders.value) return []

  return allSupportedProviders.value.filter((prov) =>
    prov.display_name.toLowerCase().includes(searchTerm.value.toLowerCase()),
  )
})

const handleClick = (bank: ISupportedPaymentProvider) => {
  bankData.value = bank
  searchTerm.value = bank.display_name

  nextTick(() => {
    isDropdownVisible.value = false
  })
  bankNotSupported.value = false

  if (!checkIfSupported(bankData.value.display_name)) {
    bankNotSupported.value = true
  }
}

const addBank = async () => {
  bankNotSupported.value = false
  if (
    bankAccountToWorkOn.value &&
    !checkIfSupported(bankData.value?.display_name || '')
  ) {
    bankNotSupported.value = true
    return
  }
  try {
    const formatedSortcode = sortcode?.value.replaceAll('-', '')
    sortCodeError.value = ''
    accountNumberError.value = ''
    bankCodeError.value = ''
    if (!bankData.value?.display_name) {
      bankCodeError.value = 'Bank name is required, please select from the list'
      return
    }

    if (!formatedSortcode) {
      sortCodeError.value = 'Sort code is required'
      return
    }

    if (!accountNumber.value) {
      accountNumberError.value = 'Account number is required'
      return
    }
    isLoading.value = true

    const { status, data } = await $lenkieBankingApi.post<IBank>(
      `/OpenBanking/BankAccounts/${profileStore.data?.organisation?.id}`,
      {
        id: Guid.newGuid(),
        provider_id: bankData.value?.provider_id,
        provider_logo_url: bankData.value?.logo_url,
        bank_name: bankData.value?.display_name,
        account_number: accountNumber.value,
        sort_code: formatedSortcode,
        account_name: bankData.value?.display_name,
        accounting_platform_id: accountingPlatformId.value
          ? accountingPlatformId.value
          : null,
        account_nickname: bankAccountNickName.value.trim()
          ? bankAccountNickName.value
          : null,
      },
    )
    if (status === 200) {
      await refetchBankAccounts()
      $event('bank:added', data)
      $event('track:mixpanel', { event: 'Bank account added', data })
      setModalState({ isOpen: false, type: 'addbank' })
      isLoading.value = false
      toast({
        title: 'Successful',
        description: 'Bank account added successfully',
        variant: 'default',
      })
    }
  } catch (error) {
    setModalState({ isOpen: false, type: 'addbank' })
    toast({
      title: 'Error occured',
      description: 'An error occured, please contact support for help',
      variant: 'destructive',
    })
  } finally {
    isLoading.value = false
  }
}
watch(searchTerm, () => {
  isDropdownVisible.value = true
})

watch(
  [allSupportedProviders, bankAccountToWorkOn],
  (newVal) => {
    const [listOfSupportedProviders, theBank] = newVal
    if (!theBank) return
    if (!listOfSupportedProviders) return
    if (listOfSupportedProviders.length < 1) return

    searchTerm.value = theBank.bank_name

    accountNumber.value = theBank.account_number
    accountingPlatformId.value = theBank.accounting_platform_id

    const supportPlatformNeeded = listOfSupportedProviders.find((x) => {
      return x.display_name.toLowerCase() === theBank.bank_name.toLowerCase()
    })

    if (supportPlatformNeeded) {
      handleClick(supportPlatformNeeded)
    } else {
      bankNotSupported.value = true
    }
  },
  { immediate: true },
)

function checkIfSupported(bankName: string) {
  if (!allSupportedProviders.value) return false
  return (
    allSupportedProviders.value.findIndex(
      (x) => x.display_name.toLowerCase() === bankName.toLowerCase(),
    ) !== -1
  )
}

onBeforeUnmount(() => {
  bankStore.$patch({
    bankAccountToWorkOn: null,
  })
})

const { organisationId } = storeToRefs(profileStore)
const canFetchBankAccounts = computed(() => !!organisationId.value)
const { refetch: refetchBankAccounts } = useQuery({
  queryKey: ['get-bank-accounts', organisationId],
  queryFn: () =>
    $api.banking.bankAccounts.getPreviouslyUsedBankAccounts(
      organisationId.value!,
    ),
  enabled: canFetchBankAccounts,
})

onMounted(() => {
  $event('track:mixpanel', { event: 'Add Bank Account Form Opened', data: {} })
})
</script>
