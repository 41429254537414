<template>
  <div class="bg-white">
    <div class="min-h-[15rem]">
      <div v-if="stripeLoaded">
        <form id="payment-form" class="inspectletIgnore">
          <div id="payment-element"></div>
          <Button
            id="submit"
            :disabled="isDisabled"
            class="mt-4 w-full"
            @click.prevent="submit"
          >
            {{ title }}
          </Button>
          <p id="error-message" class="mt-2 text-sm text-red-600"></p>
        </form>
      </div>
      <div
        v-else
        class="flex h-[15rem] w-full animate-pulse items-center justify-center"
      >
        <full-page-loader />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { loadStripe } from '@stripe/stripe-js/pure'
import { useProfileStore } from '@/stores/profile'
import { useToast } from '@/components/ui/toast/use-toast'
import { Button } from '@/components/ui/button'
import FullPageLoader from '~/components/loaders/full-page-loader.vue'

loadStripe.setLoadParameters({ advancedFraudSignals: false })
let stripe: any, elements: any, card: any
const config = useRuntimeConfig()
const strikeSecreteKey = ref('')
const profile = useProfileStore()
const { $lenkieBankingApi } = useNuxtApp()
const stripeLoaded = ref(false)
const title = ref('Add card')
const isDisabled = ref(false)
const { toast } = useToast()

const stripeKey = async () => {
  try {
    const orgId = profile.organisationId
    const { data } = await $lenkieBankingApi.get(
      `/Cards/Stripe/SetupIntentSecrets/${orgId}`,
    )
    if (data) {
      strikeSecreteKey.value = data?.secret
    }
  } catch (error) {}
}

onMounted(async () => {
  await stripeKey()
  if (!stripe) {
    stripe = await loadStripe(config.public.STRIPE_PUBLISHABLE_KEY)
    elements = stripe.elements()
  }
  if (strikeSecreteKey.value) {
    stripeLoaded.value = true
    nextTick(async () => {
      const options = {
        clientSecret: strikeSecreteKey.value,
      }
      elements = stripe.elements(options)
      card = elements.create('payment')
      await new Promise((resolve) => setTimeout(resolve, 100))
      if (card && elements) {
        card.mount('#payment-element')
      }
    })
  }
})

const submit = async () => {
  try {
    title.value = 'Processing ...'
    isDisabled.value = true
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${config.public.STRIPE_CARDS_REDIRECT_URL}`,
      },
    })
    if (error) {
      const messageContainer = document.querySelector('#error-message')
      messageContainer!.textContent = error.message
      title.value = 'Add card'
      isDisabled.value = false
    } else {
      toast({
        title: 'Successful',
        description: 'Your card has been added succesfully',
        variant: 'destructive',
      })
    }
  } catch (error) {}
}

onMounted(() => {})
</script>
