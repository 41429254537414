<template>
  <div class="rounded-lg border bg-white p-6 pb-12">
    <div v-if="selectedPlatform?.icon" class="mx-auto">
      <div
        v-if="
          selectedPlatform?.platform.toLocaleLowerCase() === 'quickbooks' ||
          selectedPlatform?.platform.toLocaleLowerCase() === 'xero'
        "
      >
        <img
          :src="selectedPlatform?.icon"
          :alt="selectedPlatform?.name"
          class="mx-auto my-5 h-20"
        />
      </div>
      <div v-else>
        <the-icon
          :icon-name="selectedPlatform.icon"
          :icon-style="`width: ${selectedPlatform.width}`"
          class-name="mx-auto my-3"
        />
      </div>
    </div>
    <div class="text-center">
      <DialogTitle as="h2" class="font-bold text-primary"
        >Connection started
      </DialogTitle>
      <DialogDescription class="mt-3 px-3 pb-7" @click="closeModal"
        >Your connection has started, if you experience any issue, kindly
        <button type="button" class="font-semibold text-primary">
          try again
        </button>
      </DialogDescription>
      <Button variant="destructive" @click="closeModal"
        ><PlugIcon class="mr-2 h-4 w-4" /> Cancel connection</Button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { PlugIcon } from 'lucide-vue-next'
import { useConnectionsStore } from '@/stores/connections'
import { DialogTitle, DialogDescription } from '@/components/ui/dialog'
import TheIcon from '@/components/shared/the-icon.vue'

const { selectedPlatform } = useConnectionsStore()
const emit = defineEmits(['closeModal'])

const closeModal = () => {
  emit('closeModal')
  setTimeout(() => window.location.reload(), 500)
}
</script>
