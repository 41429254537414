<script setup lang="ts">
import { setModalState } from '~/services/modal'

function handleClick() {
  setModalState({ isOpen: false, type: 'vendorCreationSuccessMessage' })
}
const { isGNPLUser } = storeToRefs(useProfileStore())
const message = computed(() => {
  return isGNPLUser.value
    ? 'Once the vendor has been verified, the credit facility payment method will be activated.'
    : ''
})
</script>

<template>
  <div class="rounded-[0.5rem] border bg-white px-4 pb-16 pt-10 text-primary">
    <div class="space-y-3 px-4 text-center">
      <h3 class="text-xl font-bold sm:text-2xl">
        Your vendor has been created
      </h3>
      <p class="text-sm sm:text-base">
        {{ message }}
      </p>
    </div>

    <div class="px-9">
      <div
        class="mt-6 grid gap-4 text-sm text-[#3F558E]"
        :class="{
          'for-gnpl': isGNPLUser,
          'for-cardpay': !isGNPLUser,
        }"
      >
        <div
          class="border-#E2E8F0 bank flex flex-col items-center gap-2 rounded-[0.5rem] border py-4"
        >
          <IconsABank class="h-9 w-9" />
          <p class="text-sm leading-[1.5rem]">Bank transfer</p>
          <p
            class="flex items-center justify-center rounded-[1rem] bg-[#DCFCE7] px-2 py-0.5 text-[#027A48]"
          >
            Active
          </p>
        </div>
        <div
          v-if="false"
          class="border-#E2E8F0 flex flex-col items-center gap-2 rounded-[0.5rem] border py-4"
        >
          <IconsCreditCard class="h-9 w-9" />
          <p class="text-sm leading-[1.5rem]">Credit Card</p>
          <p
            class="flex items-center justify-center rounded-[1rem] bg-[#FEF3C7] px-2 py-0.5 text-[#92400E]"
          >
            Under review
          </p>
        </div>
        <div
          v-if="isGNPLUser"
          class="border-#E2E8F0 flex flex-col items-center gap-2 rounded-[0.5rem] border py-4"
        >
          <IconsHandCoins class="h-9 w-9" />
          <p class="text-sm leading-[1.5rem]">Credit facility</p>
          <p
            class="flex items-center justify-center rounded-[1rem] bg-[#FEF3C7] px-2 py-0.5 text-[#92400E]"
          >
            Under review
          </p>
        </div>
      </div>

      <p v-if="isGNPLUser" class="px-2 py-1 text-right text-xs font-medium">
        Takes less than an hour to review.
      </p>

      <Button class="mt-12 w-full" @click="handleClick"> I understand</Button>
    </div>
  </div>
</template>

<style scoped>
.for-gnpl,
.for-cardpay {
  @apply grid-cols-1;
}

.for-gnpl {
  @apply sm:grid-cols-[repeat(2,auto)];
}

.for-cardpay {
  @apply max-sm:grid-cols-1;
}

.for-gnpl .bank {
  @apply col-span-full sm:col-span-1;
}

.for-cardpay .bank {
  @apply col-span-1;
}
</style>
