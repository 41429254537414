import Joi from 'joi'

const BUSINESS_ERROR_MSG =
  'Kindly update your business information, before you can proceed.'

export const AUTHENTICATE_BACKMARKET = Joi.object().keys({
  seller_id: Joi.string().required().label('Seller').messages({
    'string.base': BUSINESS_ERROR_MSG,
    'any.required': BUSINESS_ERROR_MSG,
  }),
  shop_name: Joi.string().min(1).required().label('Shop name').messages({
    'string.base': 'Kindly fill in your shop name',
    'any.required': 'Kindly fill in your shop name',
  }),
  email: Joi.string()
    .allow('')
    .when('access_token', {
      is: Joi.exist(),
      then: Joi.optional(),
      otherwise: Joi.required(),
    })
    .label('Your email')
    .messages({
      'string.base': 'Kindly fill in your email',
      'any.required': 'Kindly fill in your email',
    }),
  password: Joi.string()
    .allow('')
    .when('access_token', {
      is: Joi.exist(),
      then: Joi.optional(),
      otherwise: Joi.required(),
    })
    .label('Your Password')
    .messages({
      'any.required': 'Kindly fill in your password',
    }),
  access_token: Joi.string().allow('').min(1).label('Access Token'),
})
