<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import { ChevronRightIcon, ChevronLeftIcon } from 'lucide-vue-next'
import TheIcon from '../../the-icon.vue'
import addBank from './add-bank.vue'
import { isLastInArray } from '@/lib/utils'
import type { IBank } from '~/shared/interfaces'
import FullPageLoader from '@/components/loaders/full-page-loader.vue'
import { setModalState } from '@/services/modal'

const bankStore = useBankStore()
// const { bankAccountToWorkOn } = storeToRefs(useBankStore())
const tabs = [
  { label: 'From Accounting Software', value: 'software' },
  { label: 'Manually', value: 'manual' },
] as const

type ActiveTab = (typeof tabs)[number]['value']

const activeTab = ref<ActiveTab>(tabs[0].value)

const showConnections = ref(true)

const { organisationId } = storeToRefs(useProfileStore())
const hasOrganisationId = computed(() => !!organisationId.value)
const { $api } = useNuxtApp()

const {
  data: bankAccounts,
  isLoading: isLoadingBankAccounts,
  refetch: refetchBankAccounts,
} = useQuery({
  queryKey: ['get-bank-accounts'],
  queryFn: () =>
    $api.banking.bankAccounts.getPreviouslyUsedBankAccounts(
      organisationId.value!,
    ),
  enabled: hasOrganisationId,
  select(data) {
    return data.data
  },
})

const unsyncedBankAccounts = computed(() => {
  if (!bankAccounts.value) return []

  return bankAccounts.value.filter((bankAcc) => !bankAcc.provider_id)
})

function handleBankAccountClick(bank: IBank) {
  bankStore.$patch({ bankAccountToWorkOn: bank })
  showConnections.value = false
}

function goBack() {
  bankStore.$patch({ bankAccountToWorkOn: null })
  showConnections.value = true
}

const canShowTabs = computed(
  () => activeTab.value === 'manual' || showConnections.value === true,
)

onBeforeUnmount(() => {
  bankStore.$patch({ bankAccountToWorkOn: null })
  setModalState({ isOpen: false, type: 'addBankAdvance' })
  refetchBankAccounts()
})
</script>

<template>
  <div class="max-w-full rounded-[0.5rem] border bg-white p-8">
    <div v-if="canShowTabs" class="flex justify-center">
      <Tabs v-model:model-value="activeTab" :default-value="activeTab">
        <TabsList
          class="grid grid-cols-2 bg-slate-100 max-sm:grid-cols-[auto,auto]"
        >
          <TabsTrigger
            v-for="tab in tabs"
            :key="tab.value"
            :value="tab.value"
            :class="activeTab === tab.value ? 'bg-white' : ''"
            class="text-sm max-sm:text-xs"
            >{{ tab.label }}</TabsTrigger
          >
        </TabsList>
      </Tabs>
    </div>
    <div v-if="activeTab === 'manual'" class="pt-5">
      <add-bank class="border-0 !p-0" />
    </div>

    <div v-if="activeTab === 'software'">
      <Button
        v-if="!showConnections"
        variant="ghost"
        size="lg"
        class="mb-5 pl-1 pr-3 text-base text-primary lg:text-lg"
        @click="goBack"
      >
        <ChevronLeftIcon class="h-6 w-6" />
        Back</Button
      >

      <full-page-loader v-if="isLoadingBankAccounts" label="" />

      <template v-if="showConnections">
        <div class="my-5 text-center">
          <p class="font-bold sm:text-lg">Select bank account</p>
          <p class="text-sm">
            Add a GBP bank account for payment reconciliations.
          </p>
        </div>

        <div
          v-for="(bankAcc, idx) in unsyncedBankAccounts"
          :key="bankAcc.id"
          role="button"
          class="cursor-pointer border-b bg-black bg-opacity-0 px-2 py-4 text-primary hover:bg-opacity-5"
          :class="{
            'border-b-0': isLastInArray(unsyncedBankAccounts, idx),
          }"
          @click="handleBankAccountClick(bankAcc)"
        >
          <div>
            <div class="flex items-center gap-3 text-sm font-medium">
              <img
                v-if="bankAcc.provider_logo_url"
                :src="bankAcc.provider_logo_url"
                alt=""
                class="h-10 w-10 rounded-full border p-1"
              />

              <div
                v-else
                class="flex h-10 w-10 items-center justify-center rounded-full border"
              >
                <TheIcon icon-name="bank" :size="'s'" />
              </div>

              <div>
                <p>{{ bankAcc.bank_name }}</p>

                <p
                  v-if="bankAcc.account_number"
                  class="text-xs text-black text-opacity-80"
                >
                  Account no: {{ bankAcc.account_number }}
                </p>
              </div>

              <ChevronRightIcon class="ml-auto" />
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <add-bank class="border-0 !p-0" :show-title="false" />
      </template>
    </div>
  </div>
</template>
