<template>
  <div class="h-[665px] w-[656px] rounded-lg border bg-white p-3">
    <div class="h-[292px] w-full bg-slate-50">
      <iframe
        width="100%"
        height="292"
        src="https://www.youtube.com/embed/FelVRr-54oU?si=wzGWB2szAj_wGl-d"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>

    <div class="mt-5">
      <h3 class="text-center text-xl font-bold text-primary">
        What Lenkie has for you?
      </h3>

      <div class="grid grid-cols-2 gap-3 p-4">
        <div v-for="feat in features" :key="feat" class="flex gap-x-1">
          <div><the-icon icon-name="checkbox" size="s" /></div>
          <div class="text-sm text-primary">{{ feat }}</div>
        </div>
      </div>

      <div class="h-[105px] rounded-t-lg bg-[#F3F7FF] px-5 py-[18px]">
        <div class="flex items-center">
          <div>
            <the-icon icon-name="gift" size="xxl" />
          </div>
          <div class="ml-4 text-sm text-primary">
            If you are seeing this offer you have been selected for a 3 month
            FREE TRIAL. To activate this trial you simply need to make 3 direct
            bank payments or forward 5 invoices via your custom email before
            March 31st 2024
          </div>
        </div>
      </div>
      <div
        class="w-full rounded-b-lg bg-primary py-2.5 text-center text-sm font-bold text-white"
      >
        Standard pricing starts at £49/month
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TheIcon from '@/components/shared/the-icon.vue'

const features = ref([
  'All your payments in one place',
  'Sync all payments with Xero or Quickbooks',
  'AI assisted invoice parser to reduce errors',
  'Universal card payments (CardPay)',
  'Pay directly from your bank account',
  'Automatically add invoice by forwarding to your custom email',
])
</script>
